<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies files_overview">
			<div class="a_header pe-0">
				<div>
					<div class="search">
						<img class="serch_icon" src="/images/search-icon.svg" alt="">
						<input type="text" class="form-control" v-model="form.keyword" placeholder="Search Sent Faxes" @keyup.enter="getInboxSentList" @input="debouncedSearch">
					</div>
					<span class="position-relative">
						<button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter</button>
						<button class="comman_btn" @click="addFilter" v-else>Filters | {{ this.filterList.length }}</button>
						<div class="filterOptions-select">
							<CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter"
								@custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getInboxSentList">
							</CustomFilter>
						</div>
					</span>
				</div>
			</div>
			<div class="table-responsive consult-list inbox-table-list">
				<table class="table">
					<thead>
						<tr>
							<th scope="col">Sent Date Time <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="col">Fax Subject <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="col">Recipient Fax <img src="/images/down-arrow-new.svg" class="img-fluid" ></th>
							<th scope="col">Status <img src="/images/down-arrow-new.svg" class="img-fluid"></th>
							<th scope="col" class="hover_unset cursor-default" style="width: calc(var(--scale-ratio) * 140px);"></th>
						</tr>
					</thead>
					<tbody>
						<template v-if="this.faxesData.records">
							<tr v-for="(item,index) in this.faxesData.records" :key="index">
								<td>{{ $filters.inputDateTime(item.creationTime, 'YYYY-MM-DD hh:mm:ss A') }}</td>
								<td>{{ item.id }}</td>
								<td>{{ getFormattedPhoneNumbers(item.to) }}</td>
								<td><span :class="{'success-lable':item.messageStatus!='SendingFailed', 'failed-lable':item.messageStatus=='SendingFailed'}" >{{ item.messageStatus }}</span></td>
								<td class="edit_delet_link text-start">
									<span class="popup_link" v-if="item.messageStatus=='Sent'"><a target="_blank" @click="viewPdfRingcentral(item)">View</a></span>
									<span class="popup_link" @click="resendRingcentralFax(item)" v-if="item.messageStatus=='SendingFailed'"><a target="_blank">Resend</a></span>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr v-for="(item,index) in this.faxesData" :key="index">
								<td>
									<a target="_blank" class="rcivd_date_line" @click="viewPdf(item)">{{ item.DateSent }}</a>
								</td>
								<td>{{ item.Subject }}</td>
								<td>{{ item.ToFaxNumber }}</td>
								<td><span :class="{'success-lable':item.SentStatus!='Failed', 'failed-lable':item.SentStatus=='Failed'}" >{{ item.SentStatus }}</span></td>
								<td class="edit_delet_link text-start">
									<span class="popup_link" v-if="item.SentStatus=='Sent'"><a target="_blank" @click="viewPdf(item)">View</a></span>
									<span class="popup_link" @click="resendFax(item)" v-if="item.SentStatus=='Failed'"><a target="_blank">Resend</a></span>
								</td>
							</tr>
						</template>

					</tbody>
				</table>
			</div>

			<nav aria-label="Page navigation" v-if="this.faxesData.records">
				<ul class="pagination">
					<li class="page-item" :class="{ disabled: currentPage === 1 }">
						<a class="page-link" href="#" @click.prevent="goToPage(1)">First</a>
					</li>
					<!-- Previous Page Link -->
					<li class="page-item" :class="{ disabled: currentPage === 1 }">
						<a class="page-link" href="#" @click.prevent="goToPage(currentPage - 1)">Previous</a>
					</li>
					<!-- Page Number Links -->
					<li 
					class="page-item" 
					:class="{ active: page === currentPage }" 
					v-for="page in visiblePages"
					:key="page"
					>
					<a 
						class="page-link" 
						href="#" 
						@click.prevent="goToPage(page)"
					>
						{{ page }}
					</a>
					</li>
					<li class="page-item" v-if="(currentPage + 1) < totalPages">
						<a class="page-link" href="javascript::void(0)" >...</a>
					</li>

					<li class="page-item" v-if="(currentPage + 1) < totalPages">
						<a class="page-link" href="javascript::void(0)" @click.prevent="goToPage(totalPages)">{{ totalPages }}</a>
					</li>

					<!-- Next Page Link -->
					<li class="page-item" :class="{ disabled: currentPage === totalPages }">
					<a class="page-link" href="#" @click.prevent="goToPage(currentPage + 1)">Next</a>
					</li>
					<li class="page-item" :class="{ disabled: totalPages <= 1 || currentPage === totalPages  }">
						<a class="page-link" href="#" :class="{ disabled: totalPages <= 1 }" @click.prevent="goToPage(totalPages)">Last</a>
					</li>
				</ul>
			</nav>
		</div>
	</div>
</template>
<script>
import sidebar from './sidebar.vue';
import CustomFilter from '../base/CustomFilter.vue';
import axios from "@/scripts/axios.js";

export default {
	data() {
		return {
			filterList:[],
			isShowCustomFilter:false,
			filterOptions:[
				{ value: "received_timerange", title: "Sent Date Time" },
				{ value: "Subject", title: "Fax Subject" },
				{ value: "ToFaxNumber", title: "Recipient Fax" },
				{ value: "SentStatus", title: "Status" },
			],
			faxesData:[],
			form: {
				keyword: "",
				advanceFilter: []
			},
			defaultFormData:{
				keyword: "",
				advanceFilter: []
			},
			currentPage: 1, // Current page
			totalPages: 1,
			pageLimit: 5
		}
	},
	components: {
		sidebar,
		CustomFilter
	},
	mounted(){
		this.resetFormData()
		document.addEventListener('click', this.handleClickOutside);
		this.getInboxSentList()
	},
	methods:{
		handleClickOutside(event) {
			if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
				this.getInboxSentList()
				this.isShowCustomFilter = false;
			}

		},
		addFilter(){
			this.isShowCustomFilter = !this.isShowCustomFilter;
		},
		getInboxSentList(){
			this.$store.state.loader = true;
			axios.post("inbox/get-faxes-sent-list", {
				keyword: this.form.keyword,
				advanceFilter: this.form.advanceFilter,
				sort_by: this.sortBy,
				sort_order: this.sortOrder,
				page: this.currentPage
			}).then((response) => {
				let detail = response.data.data
				if (detail.redirect_url) {
					window.open(detail.redirect_url, "_blank");
				} else {
					this.faxesData = detail
					this.totalPages = detail.paging.totalPages
					this.currentPage = detail.paging.page
				}
				this.$store.state.loader = false;
			}).catch(error => {
				if(error.response.status === 402){
					window.location.href = error.response.data.redirect_url;
				}
				this.$store.state.loader = false;
				this.$filters.moshaToast(error.response.data.message, "error");
			});

		},
		handleCustomField(values){
			this.filterList = values
			let advanceFilter={}
			values.forEach(item => {
                let value = item.value
                if (item.value == 'Not Set') {
                    value = ''
                }
                advanceFilter[item.field] = value
            });
			this.form.advanceFilter = advanceFilter
		},
		debouncedSearch() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.getInboxSentList()
			}, 1000);
		},
		resendFax(data){
			this.$store.state.loader = true;
			axios.post("inbox/resend-fax", data).then((response) => {
				this.$store.state.loader = false;
				if (response.status == 200){
					this.$filters.moshaToast("Fax resend successfully", "success")
				} else {
					this.$filters.moshaToast(response.data.message, "error")
				}
			}).catch(error => {
				this.$store.state.loader = false;
				this.$filters.moshaToast(error.response.data.message, "error");
			});

		},
		resendRingcentralFax(data){
			this.$store.state.loader = true;
			axios.post("admin/ringcentral/resend-fax", data).then((response) => {
				this.$store.state.loader = false;
				if (response.status == 200){
					const detail = response.data?.data;
					if (detail?.redirectUrl) {
						window.open(detail.redirectUrl, "_blank");
					} else {
						this.$filters.moshaToast("Fax resend successfully", "success")
						this.getInboxSentList()
					}
				} else {
					this.$filters.moshaToast(response.data.message, "error")
				}
			}).catch(error => {
				console.log(error)
				this.$store.state.loader = false;
				this.$filters.moshaToast(error.response.data.message, "error");
			});
		},
		viewPdf(data){
			this.$store.state.loader = true;
			axios.post("inbox/retrieve-pdf", {'FileName':data.FileName}).then((response) => {
				this.$store.state.loader = false;
				if (response.status == 200){
					let responseData = response.data.data
					const pdfData = responseData.startsWith('data:application/pdf;base64,') ? responseData : `data:application/pdf;base64,${responseData}`;
					this.openbase64File(pdfData)

				} else {
					this.$filters.moshaToast(response.data.message, "error")
				}
			}).catch(error => {
				this.$store.state.loader = false;
				this.$filters.moshaToast(error.response.data.message, "error");
			});

		},
		viewPdfRingcentral(data){
			this.$store.state.loader = true;
			data.attachments.forEach(item => {
				axios.post("admin/ringcentral/get-file-content", {'attachment':item}).then((response) => {
					if (response.data.data.fileContent) {
						let responseData = response.data.data.fileContent
						const pdfData = responseData.startsWith('data:application/pdf;base64,') ? responseData : `data:application/pdf;base64,${responseData}`;
						this.openbase64File(pdfData)
					} else {
						this.$filters.moshaToast("Please try again", "error");
					}
				})
			})
			this.$store.state.loader = false;
		},
		resetFormData(){
			Object.assign(
				this.form,
				this.defaultFormData,
			);
		},
		getFormattedPhoneNumbers(recipients) {
			return recipients.map(recipient => recipient.phoneNumber).join(", ");
		},
		openbase64File(pdfData){
			// Convert the base64 string to a Blob
			const byteCharacters = atob(pdfData.split(',')[1]);

			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			const blob = new Blob([byteArray], { type: 'application/pdf' });

			// Create an object URL from the Blob
			const objectUrl = URL.createObjectURL(blob);

			// Open the PDF in a new tab
			window.open(objectUrl, '_blank');
		},
		goToPage(page){
			this.currentPage = page
			this.getInboxSentList()
		}

	},
	computed: {
		visiblePages() {
			// Calculate the range of visible pages
			const startPage = Math.max(1, this.currentPage - 1); // Show previous page
			const endPage = Math.min(this.totalPages, this.currentPage + 1); // Show next page
			
			const pages = [];
			// Loop to add pages to the array
			for (let i = startPage; i <= endPage; i++) {
				pages.push(i);
			}
			return pages;
		},
	},
}
</script>